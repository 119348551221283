import {
  DeleteButton,
  FormTab,
  SaveButton,
  TabbedForm,
  Toolbar,
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import Banner from '../../../components/Banner';
import RequestTab from '../tabs/RequestTab';
import OfferTab from '../tabs/OfferTab';
import StaffingTab from '../tabs/StaffingTab';
import BillingTab from '../tabs/BillingTab';
import ContactsTab from '../tabs/ContactsTab';
import NotesTab from '../tabs/NotesTab';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { listenToOrder } from '../../../api/orders';

export default function OrderForm() {
  const { t } = useTranslation();

  const record = useRecordContext();

  const [fetchedOrder, setFetchedOrder] = useState();

  const {
    shouldResendOfferMail,
    shouldResendStaffingMail,
    status,
    id,
    details,
  } = fetchedOrder || record || {};

  const shouldRenderTopBanner =
    shouldResendOfferMail || shouldResendStaffingMail;
  const isBilledOrCompleted = status.match(/completed|billed/);
  const isAnySessionApproved = details.shifts?.find(({ sessionApprovals }) =>
    sessionApprovals ? sessionApprovals.find(s => s.isApproved) : false,
  );
  const canDeleteOrder = !isBilledOrCompleted && !isAnySessionApproved;

  // Listen to any state change in the order.
  useEffect(() => {
    if (id) {
      const unsubscribeOrder = listenToOrder(id, data => setFetchedOrder(data));
      return unsubscribeOrder;
    }
  }, [id]);

  const bannersOverTabs = [
    {
      label: t(
        'Offer items have been changed, please re-send the offer email to notify the clients.',
      ),
      isVisible: shouldResendOfferMail,
      severity: 'error',
    },
    {
      label: t(
        'New nannies have been assigned, please re-send the staffing email to notify the clients.',
      ),
      isVisible: shouldResendStaffingMail,
      severity: 'error',
    },
  ];

  const commonBanner = {
    label: t("These values can't be changed after the client has been billed."),
    isVisible: status === 'billed',
    severity: 'info',
    closable: true,
  };

  const tabs = [
    {
      label: t('Request'),
      content: <RequestTab record={fetchedOrder || record} />,
      banners: [commonBanner],
    },
    {
      label: t('Contacts'),
      content: <ContactsTab record={fetchedOrder || record} />,
      banners: [commonBanner],
    },
    {
      label: t('Staffing'),
      content: <StaffingTab record={fetchedOrder || record} />,
      banners: [commonBanner],
    },
    {
      label: t('Offer'),
      content: <OfferTab record={fetchedOrder || record} />,
      banners: [commonBanner],
    },

    {
      label: t('Billing'),
      content: <BillingTab record={fetchedOrder || record} />,
      banners: [commonBanner],
    },
    {
      label: t('Notes'),
      content: <NotesTab />,
    },
  ];

  const topBanners = (
    <Toolbar>
      {bannersOverTabs.map(
        ({ label, severity, isVisible }) =>
          isVisible && (
            <Banner
              label={label}
              severity={severity}
              show={isVisible}
              closable={false}
            />
          ),
      )}
    </Toolbar>
  );

  const CustomToolbar = () => (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <SaveButton />
      {canDeleteOrder && <DeleteButton />}
    </Toolbar>
  );

  return (
    <Box>
      {shouldRenderTopBanner && topBanners}
      <TabbedForm sanitizeEmptyValues toolbar={<CustomToolbar />}>
        {tabs.map(tab => (
          <FormTab key={tab.label} label={tab.label}>
            <div style={styling.bottomPadding}>
              {tab.banners?.length
                ? tab.banners.map(
                    ({ label, severity, isVisible, closable }) =>
                      isVisible && (
                        <Banner
                          key={label}
                          severity={severity}
                          label={label}
                          show={isVisible}
                          closable={closable}
                        />
                      ),
                  )
                : null}
              {tab.content}
            </div>
          </FormTab>
        ))}
      </TabbedForm>
    </Box>
  );
}

const styling = {
  bottomPadding: {
    paddingBottom: '50px',
    width: '100%',
  },
};
